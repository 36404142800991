.guest {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
}

.guest ul {
    display: flex;
    justify-content: space-evenly;
}
